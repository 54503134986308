
// document.getElementById('search-panel').addEventListener('click', function() {
//     var searchWrapper = document.querySelector('.search-wrapper');
//     searchWrapper.classList.toggle('active-search');
//     if (searchWrapper.classList.contains('active-search')) {
//       document.getElementById('search-input').focus();
//     }
//   });

//.contact-nav

var tele = document.querySelector('.contact-nav');
tele.addEventListener('mouseover', function () {
    var telemenu = document.querySelector('.contact-nav__list');
    telemenu.style.display = 'block';
});

tele.addEventListener('mouseout', function () {
    var telemenu = document.querySelector('.contact-nav__list');
    telemenu.style.display = 'none';
});


// swiper

let inited = false;
document.addEventListener('page:loaded', startFrontController);

if(inited){
    document.addEventListener('DOMContentLoaded', startFrontController);

}
function startFrontController() {
    const defaultSlider = new Swiper('.default-slider', {
        spaceBetween: 30,
        loop: true,
        navigation: {
            nextEl: '.slider-next',
            prevEl: '.slider-prev',
        },
        slidesPerView: 'auto',
    });

// burger


        var menuButton = document.querySelector('.menu-button');
        var hiddenMenu = document.querySelector('.hidden-menu');
        var overlay = document.querySelector('.overlay');
        var bodyOverflow = document.querySelector('.layout-home');

        menuButton.addEventListener('click', function () {
            hiddenMenu.classList.toggle('show');
            overlay.classList.toggle('show');
            bodyOverflow.classList.toggle('show');
        });
        hiddenMenu.addEventListener('click', function (event) {
            if (event.target.classList.contains('close-button')) {
                hiddenMenu.classList.remove('show');
                overlay.classList.remove('show');
                bodyOverflow.classList.remove('show');
            }
        });


// modal-login

    function openModalLogin() {
        let modalLogin = document.getElementById('modal-login');
        modalLogin.style.display = 'block';
        modalLogin.style.opacity = '1';
        modalLogin.classList.remove('invisible');
    }

    let openButtonLogin = document.querySelector('.btn-modal-login');

    if (openButtonLogin) {
        openButtonLogin.addEventListener('click', openModalLogin);
    }

    function closeModalLogin() {
        let modalLogin = document.getElementById('modal-login');
        modalLogin.style.display = 'none';
        modalLogin.style.opacity = '0';
        modalLogin.classList.add('invisible');
    }

    let closeButtonLogin = document.querySelector('.modallogin-close');
    closeButtonLogin.addEventListener('click', closeModalLogin);
    let closeButtonLogin2 = document.querySelector('.btn-modal-registration');
    closeButtonLogin2.addEventListener('click', closeModalLogin);

    window.onclick = function (event) {
        let modalLogin = document.getElementById('modal-login');
        if (event.target == modalLogin) {
            closeModalLogin();
        }
    }

// modal-registration

    function openModalRegistration() {
        let modalRegistration = document.getElementById('modal-registration');
        modalRegistration.style.display = 'block';
        modalRegistration.style.opacity = '1';
        modalRegistration.classList.remove('invisible');
    }

    let openButtonRegistration = document.querySelector('.btn-modal-registration');
    openButtonRegistration.addEventListener('click', openModalRegistration);

    function closeModalRegistration() {
        let modalRegistration = document.getElementById('modal-registration');
        modalRegistration.style.display = 'none';
        modalRegistration.style.opacity = '0';
        modalRegistration.classList.add('invisible');
    }

    let closeButtonRegistration = document.querySelector('.modal-close-registration');
    closeButtonRegistration.addEventListener('click', closeModalRegistration);

    window.onclick = function (event) {
        let modalRegistration = document.getElementById('modal-registration');
        if (event.target == modalRegistration) {
            closeModalRegistration();
        }
    }

// modal-buyer

    function openModalBuyer() {
        let modalBuyer = document.getElementById('modal-buyer');
        modalBuyer.style.display = 'block';
        modalBuyer.style.opacity = '1';
        modalBuyer.classList.remove('invisible');
    }

    let openButtonBuyer = document.querySelector('.btn-buyer');
    openButtonBuyer.addEventListener('click', openModalBuyer);

    function closeModalBuyer() {
        let modalBuyer = document.getElementById('modal-buyer');
        modalBuyer.style.display = 'none';
        modalBuyer.style.opacity = '0';
        modalBuyer.classList.add('invisible');
    }

    let closeButtonBuyer = document.querySelector('.modal-buyer-close');
    if (closeButtonBuyer) {
        closeButtonBuyer.addEventListener('click', closeModalBuyer);
    }

    let closeButtonBuyer2 = document.querySelector('.btn-buyer-registration');
    if (closeButtonBuyer2) {
        closeButtonBuyer2.addEventListener('click', closeModalBuyer);
    }


    window.onclick = function (event) {
        let modalBuyer = document.getElementById('modal-buyer');
        if (event.target == modalBuyer) {
            closeModalBuyer();
        }
    }

// modal-buyer-registration
    function openModalBuyerRegistration() {
        let modalBuyerRegistration = document.getElementById('modal-buyer-registration');
        modalBuyerRegistration.style.display = 'block';
        modalBuyerRegistration.style.opacity = '1';
        modalBuyerRegistration.classList.remove('invisible');
    }



    function closeModalBuyerRegistration() {
        let modalBuyerRegistration = document.getElementById('modal-buyer-registration');
        modalBuyerRegistration.style.display = 'none';
        modalBuyerRegistration.style.opacity = '0';
        modalBuyerRegistration.classList.add('invisible');
    }

    let closeButtonBuyerRegistration = document.querySelector('.modal-buyer-reg-close');
    closeButtonBuyerRegistration.addEventListener('click', closeModalBuyerRegistration);

    window.onclick = function (event) {
        let closeModalBuyerRegistration = document.getElementById('modal-buyer-registration');
        if (event.target == closeModalBuyerRegistration) {
            closeModalBuyerRegistration();
        }
    }

// personal-account

    let tabsBtn = document.querySelectorAll('.account-btn');
    let tabsItem = document.querySelectorAll('.account-item');

    tabsBtn.forEach(function (element) {
        element.addEventListener('click', function (e) {
            const path = e.currentTarget.dataset.path;

            tabsBtn.forEach(function (btn) {
                btn.classList.remove('account-btn--active')
            });
            e.currentTarget.classList.add('account-btn--active');

            tabsItem.forEach(function (element) {
                element.classList.remove('account-item--active')
            });
            document.querySelector(`[data-target="${path}"]`).classList.add('account-item--active');
        });
    });

// feedback


        const modalForm = document.querySelector('.feedback-form');

        function openModalApplication() {
            const modalApplication = document.getElementById('modal-application');
            modalApplication.style.display = 'block';
            modalApplication.style.opacity = '1';
            modalApplication.classList.remove('invisible');
        }

        function closeModalApplication() {
            let modal = document.getElementById('modal-application');
            modal.style.display = 'none';
            modal.style.opacity = '0';
            modal.classList.add('invisible');
        }

        let closeButtonApplication = document.querySelector('.modal-close-application');
        closeButtonApplication.addEventListener('click', closeModalApplication);

        function openModal() {
            let modal = document.getElementById('feedback');
            modal.style.display = 'block';
            modal.style.opacity = '1';
            modal.classList.remove('invisible');
        }

        function closeModal() {
            let modal = document.getElementById('feedback');
            modal.style.display = 'none';
            modal.style.opacity = '0';
            modal.classList.add('invisible');
        }

        let openButton = document.querySelector('.btn-feedback');
        if (openButton) {
            openButton.addEventListener('click', openModal);
        }

        let openButton2 = document.querySelector('.btn-feedback-hero');
        if (openButton2) {
            openButton2.addEventListener('click', openModal);
        }

        let closeButtonModal = document.querySelector('.feedback-close');
        closeButtonModal.addEventListener('click', closeModal);
    if (modalForm) {

        modalForm.addEventListener('submit', (event) => {
            event.preventDefault();
            openModalApplication();
            closeModal();
        });
    }
// modal-thanks

// cart

    // let carttabsBtn = document.querySelectorAll('.cart-btn');
    // let carttabsItem = document.querySelectorAll('.cart-item');
    //
    // carttabsBtn.forEach(function (element) {
    //     element.addEventListener('click', function (e) {
    //         const path = e.currentTarget.dataset.path;
    //
    //         carttabsBtn.forEach(function (btn) {
    //             btn.classList.remove('cart-btn--active')
    //         });
    //         e.currentTarget.classList.add('cart-btn--active');
    //
    //         carttabsItem.forEach(function (element) {
    //             element.classList.remove('cart-item--active')
    //         });
    //         document.querySelector(`[data-target="${path}"]`).classList.add('cart-item--active');
    //     });
    // });
    function toggleFill() {
        var svgElement = document.querySelector('.changeable-fill');
        svgElement.classList.toggle('red-fill');
    }

    function toggleFill2() {
        var svgElement = document.querySelector('.changeable-fill-2');
        svgElement.classList.toggle('white-fill');
    }
    function toggleFill3(buttonElement) {
        var listItem = buttonElement.closest('li');
        var svgElement = listItem.querySelector('.changeable-fill');
        if (svgElement) {
            svgElement.classList.toggle('red-fill');
        }
    }
    function toggleFill4(buttonElement) {
        var listItem = buttonElement.closest('li');
        var svgElement = listItem.querySelector('.changeable-fill-2');
        if (svgElement) {
            svgElement.classList.toggle('white-fill');
        }
    }
}
